import {makeStyles} from '@material-ui/core/styles';
import {fontWeightMedium} from 'lib/mui-config';
import {isMobileAppQuery} from 'lib/app-theme';
const useStyles = makeStyles(
  (theme) => ({
    content: {
      backgroundColor: theme.palette.white.light,
      display: theme.withPrint('flex', 'block'),
    },
    paymentBreakdownListItem: {
      fontStyle: 'normal',
      fontWeight: fontWeightMedium,
      fontSize: theme.withPrint(theme.spacing(2.75), theme.spacing(3.75)),
      lineHeight: theme.withPrint(theme.spacing(1.875), theme.spacing(2.25)),
      color: theme.palette.borrower.dark,
      justifyContent: 'space-between',
      [isMobileAppQuery(theme)]: {
        padding: theme.spacing(1),
      },
    },
    flexAlign: {
      flex: 1,
    },
    text2left: {
      textAlign: 'left',
    },
    childElement: {
      width: 'auto',
      marginLeft: ({deep}) => deep * theme.spacing(2),
      [isMobileAppQuery(theme)]: {
        marginLeft: ({deep}) => deep * theme.spacing(1),
      },
    },
    text2right: {
      textAlign: 'right',
    },
    listItemText: {
      '& span': {},
    },
    customToolTip: {
      position: 'absolute',
      top: 0,
      left: theme.spacing(0.5),
      width: '20px',
      height: '20px',
    },
    listSubElement: {
      '& span': {
        fontWeight: 500,
      },
    },
  }),
  {index: 1},
);
export default useStyles;