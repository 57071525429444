import React, {useMemo} from 'react';
import {formatCurrency, formatPercent} from 'lib/currency';
import {useTranslation} from 'lib/i18n';
import {getBorrowerBirthDate} from 'lib/borrower';
import {usePrintContext} from 'contexts/print-context';
import {useBooleanToString} from 'hooks/currency';
import {parentheses} from 'lib/utils';
import {isPaymentBreakdownValueNegative, formatPaymentBreakdownValue} from 'lib/product';

export function useSelectOverallResults(props = {}) {
  const {
    product: {
      ProductName,
      calculation: {
        BorrowerProfile: {
          Age,
          DateOfBirth,
          Liens,
          ValueOfProperty,
          ExistingMortgage: {TaxesAndInsuranceReserve} = {},
        } = {},
        PrincipalLimit,
        ClosingCost,
        CashAtClose,
        TotalUpfrontDraw,
        MaxRevolvingLoc,
        LineOfCredit,
        Rate,
        Fee,
        HomeSafeTier,
        FundAccess,
        Lesa,
        OverrideClosingCosts,
        RemainingPrincipalLimit,
        MinimumInitialDraw,
        upfrontDrawPercentage,
      } = {},
    } = {},
  } = props;

  const {t, cashAtCloseLabel} = useTranslation();
  const {printing} = usePrintContext();

  const {booleanToString} = useBooleanToString();

  // This is used on the Product PDF Printout page
  const overallResults = useMemo(
    () => [
      {
        label: t('illustratorTranslation:illustration.overall results.home value'),
        value: formatCurrency(ValueOfProperty),
        order: 1,
        id: 'home-value',
      },
      {
        label: t('illustratorTranslation:illustration.overall results.principal limit'),
        tooltip: t('illustratorTranslation:illustration.overall results.principal limit tooltip'),
        value: formatCurrency(PrincipalLimit),
        order: 2,
        id: 'principal-limit',
        options: [
          {
            label: t('illustratorTranslation:illustration.overall results.mortgage balance'),
            value: parentheses(formatCurrency(Liens)),
            useColorOptions: {value: 'red'},
            order: 3,
            id: 'mortgage-balance',
          },
          {
            label: t('illustratorTranslation:illustration.overall results.closing cost'),
            value: parentheses(formatCurrency(ClosingCost)),
            useColorOptions: {value: 'red'},
            order: 4,
            id: 'closing-cost',
          },
        ],
      },
      {
        label: cashAtCloseLabel('illustratorTranslation:illustration.overall results.cash at close', {
          cashAtClose: CashAtClose,
        }),
        value: formatPaymentBreakdownValue(CashAtClose),
        useColorOptions: {value: isPaymentBreakdownValueNegative(CashAtClose) ? 'red' : undefined},
        order: 6,
        id: 'cash-at-close',
      },
      {
        label: t('illustratorTranslation:illustration.overall results.total upfront draw'),
        value: formatCurrency(TotalUpfrontDraw),
        order: 7,
        id: 'total-upfront-draw',
      },
      {
        label: t('illustratorTranslation:illustration.overall results.maximum line of credit'),
        value: formatCurrency(MaxRevolvingLoc),
        order: 8,
        id: 'maximum-line-of-credit',
      },
      {
        label: t('illustratorTranslation:illustration.overall results.available line of credit'),
        value: formatCurrency(LineOfCredit),
        order: 9,
        id: 'available-line-of-credit',
      },
    ],
    [
      t,
      cashAtCloseLabel,
      ValueOfProperty,
      PrincipalLimit,
      Liens,
      ClosingCost,
      CashAtClose,
      TotalUpfrontDraw,
      MaxRevolvingLoc,
      LineOfCredit,
    ],
  );

  // This is used on the Product PDF Printout page
  const overallResultsSecond = useMemo(() => {
    const data = [
      {
        label: t('illustratorTranslation:illustration.overall results.date of birth'),
        value: getBorrowerBirthDate(DateOfBirth),
        order: 1,
        id: 'date-of-birth',
      },
      {
        label: t('translation:justText.estimated age'),
        tooltip: t('translation:justText.estimated age tooltip'),
        value: Age,
        order: 2,
        id: 'estimated-age',
      },
      {
        label: t('illustratorTranslation:illustration.overall results.Rate'),
        value: formatPercent(Rate),
        order: 3,
        id: 'rate',
      },
    ];

    if (printing) {
      data.push(
        {
          label: t('illustratorTranslation:illustration.overall results.upfront draw percentage'),
          value: formatPercent(upfrontDrawPercentage),
          order: 7,
          id: 'upfront-draw-percentage',
        },
        {
          label: t('illustratorTranslation:illustration.overall results.fund access'),
          value: FundAccess,
          order: 8,
          id: 'fund-access',
        },
      );
    }

    return data;
  }, [t, DateOfBirth, Age, Rate, printing, upfrontDrawPercentage, FundAccess]);

  return {
    overallResults,
    overallResultsSecond,
  };
}
