import React from 'react';
import TextField from './components/TextField';
import Slider from './components/Slider';
import Currency from './components/Currency';
import ZipCode from './components/ZipCode';
import Integer from './components/Integer';
import Percent from './components/Percent';
import CustomRadioGroup from 'components/common/form/input/components/CustomRadioGroup';
import CustomRadioGroupWithIcons from 'components/common/form/input/components/CustomRadioGroupWithIcons';
import CustomCheckbox from 'components/common/form/input/components/CustomCheckbox';
import SocialSecurityNumber from 'components/common/form/input/components/SocialSecurityNumber';
import EmailWithDeleteIcon from 'components/common/form/input/components/EmailWithDeleteIcon';
import TextFieldWithToolTipIcon from 'components/common/form/input/components/TextFieldWithToolTipIcon';
import SmartyAutoComplete from 'components/common/form/input/components/SmartyAutoComplete';
import IndeterminateCheckbox from 'components/common/form/input/components/IndeterminateCheckbox';

/**
 *
 * props attributes:
 *
 *    className : string
 *    disabled : undefined || boolean
 *    error : boolean
 *    fillWidth : boolean
 *    helperText : undefined || text
 *    InputProps : obj
 *    label : string
 *    name : string
 *    onBlur : () => {...}
 *    onChange : event => {...}
 *    required : boolean
 *    toolTipTitle : string
 *    type : string
 *    value : string
 *    variant : string
 *
 */

const InputField = React.forwardRef(function InputFieldRender(props, ref) {
  const {...rest} = props;

  // the input "currency" type field
  if (rest.type === 'currency') {
    return <Currency {...rest} />;
  }
  if (rest.type === 'zip') {
    return (
      <TextField {...rest} type={undefined} ref={ref} InputProps={{...rest?.InputProps, inputComponent: ZipCode}} />
    );
  } else if (rest.type === 'percent') {
    return (
      <TextField
        {...rest}
        type={undefined}
        ref={ref}
        inputProps={{...rest?.inputProps}}
        InputProps={{...rest?.InputProps, inputComponent: Percent}}
      />
    );
  } else if (rest.type === 'range') {
    return <Slider {...rest} ref={ref} />;
  } else if (rest.type === 'integer') {
    return (
      <TextField {...rest} type={undefined} ref={ref} InputProps={{...rest?.InputProps, inputComponent: Integer}} />
    );
  } else if (rest.type === 'ssn') {
    return <SocialSecurityNumber {...rest} />;
  } else if (rest.type === 'emailWithDeleteIcon') {
    return <EmailWithDeleteIcon {...rest} />;
  } else if (rest.type === 'radio-group') {
    return <CustomRadioGroup ref={ref} {...rest} />;
  } else if (rest.type === 'radio-group-with-icons') {
    return <CustomRadioGroupWithIcons ref={ref} {...rest} />;
  } else if (rest.type === 'checkbox') {
    // a checkbox
    return <CustomCheckbox {...rest} />;
  } else if (rest.type === 'indeterminate-checkbox') {
    // a checkbox
    return <IndeterminateCheckbox {...rest} />;
  } else if (rest.type === 'textFieldWithToolTipIcon') {
    // a text field with a tool tip icon
    return <TextFieldWithToolTipIcon ref={ref} {...rest} />;
  } else if (rest.type === 'address') {
    return <SmartyAutoComplete ref={ref} {...rest} />;
  } else if (rest.type === 'hidden') {
    return null;
  }
  // the default text field
  return <TextField ref={ref} {...rest} />;
});

InputField.propTypes = {};

export default InputField;
