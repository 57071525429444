import React, {useMemo} from 'react';
import Switch from 'components/common/form/switch/Switch';
import {API_ROUTES, FUND_ACCESS} from 'constants/illustrator';
import Input from 'components/common/form/input/Input';
import {getAdvancedOptionsFieldDefaultValidation} from 'lib/validation/validation';
import {useTranslation} from 'lib/i18n';
import TierRatesSelect from 'components/products/tier/TierRatesSelect';
import FundAccessInput from 'components/common/inputs/FundAccessInput';
import TaxesAndInsuranceReserveInput from 'components/common/inputs/TaxesAndInsuranceReserveInput';
import yup from 'lib/validation';

const additionalStepValidation = yup.object({
  advancedOptions: getAdvancedOptionsFieldDefaultValidation({
    ApiRoute: API_ROUTES.TIER,
    annualPayment: yup.number().max(0),
    monthlyPayment: yup.number().max(0),
  }),
});

export function useFlexHook(props = {}) {
  const {
    product: {
      ProductName,
      calculation: {
        BorrowerProfile: {
          Age,
          DateOfBirth,
          Liens,
          ValueOfProperty,
          ExistingMortgage: {TaxesAndInsuranceReserve} = {},
        } = {},
        PrincipalLimit,
        ClosingCost,
        CashAtClose,
        FundAccess,
        Lesa,
        OverrideClosingCosts,
        RemainingPrincipalLimit,
        Rate,
        ServiceFee,
        HomeSafeTier,
      } = {},
    } = {},
  } = props;
  const {t} = useTranslation();

  return {
    description: t('illustratorTranslation:illustration.products.flex.description'),

    additionalAdvancedOptionHeader: [],
    additionalStepTitle: t('illustratorTranslation:illustration.products.flex.additional step title'),
    skipAdditionalStep: true,
    additionalStepValidation,
    additionalQuickFill: useMemo(() => [<Switch order="7" name="OverrideClosingCosts" defaultValue={false} />], []),
    additionalStep: useMemo(
      () => [
        <TierRatesSelect order="1" name="HomeSafeTier" />,
        <Switch order="2" name="LenderCredit" defaultValue={false} />,
        <Input order="3" name="YearsRemaining" type="number" defaultValue={10} />,
        <Input order="4" name="CashAtClose" type="currency" defaultValue={0} />,
        <TaxesAndInsuranceReserveInput order="5" defaultValue={0} />,
        <Input order="6" name="ClosingCost" type="currency" defaultValue={0} />,
        <FundAccessInput ApiRoute={API_ROUTES.FLEX} order="8" type="hidden" defaultValue={FUND_ACCESS.LUMP_SUM} />,
      ],
      [],
    ),
  };
}
