import React, {useMemo} from 'react';
import {API_ROUTES, FUND_ACCESS, PRODUCT_FUND_ACCESS} from 'constants/illustrator';
import Input from 'components/common/form/input/Input';
import yup from 'lib/validation';
import {getAdvancedOptionsFieldDefaultValidation, getFundAccessValidation} from 'lib/validation/validation';
import {useTranslation} from 'lib/i18n';
import Switch from 'components/common/form/switch/Switch';
import fixedImg from 'assets/images/products/hecm.svg';
import FixedRatesSelect from 'components/products/fixed/FixedRatesSelect';
import FundAccessInput from 'components/common/inputs/FundAccessInput';
import {map} from 'lodash';
import {formatCurrency} from 'lib/currency';

const additionalStepValidation = yup.object({
  FixedRate: yup.number(),
  CashAtClose: yup.number().nullable(),
  ClosingCost: yup.number().nullable(),
  FundAccess: getFundAccessValidation({values: map(PRODUCT_FUND_ACCESS[API_ROUTES.FIXED], 'value')}),
  OverrideClosingCosts: yup.bool(),
  advancedOptions: getAdvancedOptionsFieldDefaultValidation({
    ApiRoute: API_ROUTES.FIXED,
    annualPayment: yup.number().max(0),
    monthlyPayment: yup.number().max(0),
  }),
});

export function useFixedHook(props = {}) {
  const {t} = useTranslation();

  const maxClaimAmount = (props?.product?.calculation?.HecmMetric?.MaxClaimAmount) 
  ? formatCurrency(props?.product?.calculation?.HecmMetric?.MaxClaimAmount) 
  : '$0.00';

  const description = t('illustratorTranslation:illustration.products.fixed.description')
    .replace('<max claim amount>', maxClaimAmount);

  return {
    description: description,

    img: fixedImg,
    imgText: 'Fixed',
    lifetimeModelOptionHeader: useMemo(
      () => [
        {
          key: 'homeValueYearEnd',
          group: 1,
          formatter: (props) => formatCurrency(props.row.homeValueYearEnd),
          minWidth: 100,
          listData: 'homeValueYearStart',
        },
        {
          key: 'loanBalanceValueYearEnd',
          group: 2,
          formatter: (props) => formatCurrency(props.row.loanBalanceValueYearEnd),
          minWidth: 100,
          listData: 'loanBalanceValueYearStart',
        },
      ],
      [],
    ),
    additionalAdvancedOptionHeader: [],
    additionalStepTitle: t('illustratorTranslation:illustration.products.fixed.additional step title'),
    skipAdditionalStep: true,
    additionalStepValidation,
    additionalQuickFill: useMemo(
      () => [
        <FixedRatesSelect order="1" name="FixedRate" />,
        <Input order="2" name="CashAtClose" type="currency" defaultValue={0} />,
        <Input order="4" name="ClosingCost" type="currency" defaultValue={0} />,
        <Switch order="5" name="OverrideClosingCosts" defaultValue={false} />,
      ],
      [],
    ),
    additionalStep: useMemo(
      () => [
        <FundAccessInput
          order="6"
          type="hidden"
          ApiRoute={API_ROUTES.FIXED}
          defaultValue={FUND_ACCESS.LUMP_SUM}
          disabled
        />,
      ],
      [],
    ),
  };
}
