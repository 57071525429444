import React, {useMemo} from 'react';

import Container from '@material-ui/core/Container';

import useStyles from 'pages/illustrator/components/product-printout-page/components/payment-breakdown/components/list-x-mainElements-y-subElements/ListXMainElementsStyles';
import ListYSubElementsOfXiMainElement from 'pages/illustrator/components/product-printout-page/components/payment-breakdown/components/list-x-mainElements-y-subElements/list-y-sub-elements-of-xi-main-element/ListSubElements';
import {ListItemText} from '@material-ui/core';
import {useTranslation} from 'lib/i18n';
import {useProductContext} from 'contexts/product-context';
import clsx from 'clsx';
import {SqlDateFormat} from 'lib/utils';
import Item from 'components/common/layout/item/Item';
import {formatCurrency} from 'lib/currency';

/**
 *
 *  list X main elements
 *
 *  "Principle Limit Factor" list
 *  &
 *  "Details" list
 *
 */

function ListXMainElements(props) {
  const classes = useStyles();

  const {t} = useTranslation();

  const {
    product: {calculation: {SysCreateDate, Fee} = {}} = {},
    productResults: {overallResults = [], overallResultsSecond: ors = []} = {},
  } = useProductContext();

  const overallResultsSecond = useMemo(() => {
    const data = [...ors];

    data.push({
      label: t('illustratorTranslation:illustration.overall results.SysCreateDate'),
      order: 100,
      value: SqlDateFormat(SysCreateDate),
      id: 'created-date',
    });

    if (Fee) {
      data.push({
        label: t('equityavailPrintoutTranslation:fee disclosure'),
        order: 200,
        id: 'fee-disclosure-title',
        subSection: [
          {
            label: t('illustratorTranslation:illustration.overall results.monthly service fee'),
            value: formatCurrency(Fee),
            order: 201,
            id: 'monthly-fee',
          },
        ],
      });
    }

    return data;
  }, [ors, t, SysCreateDate, Fee]);

  const data = useMemo(
    () => [
      {
        name: t('equityavailPrintoutTranslation:loan proceeds breakdown'),
        data: overallResults,
      },
      {
        name: t('equityavailPrintoutTranslation:details'),
        data: overallResultsSecond,
      },
    ],
    [overallResultsSecond, overallResults, t],
  );

  return (
    <React.Fragment>
      {data.map((value, index) => {
        if (value.data.length === 0) return null;

        return (
          <React.Fragment key={index}>
            <Container className={classes.listXMainElementContainer} maxWidth={false}>
              {/* header */}
              <Item xs={12}>
                <ListItemText primary={value.name} className={clsx(classes.text2left, classes.listItemText)} />
              </Item>
              {/* content */}
              <ListYSubElementsOfXiMainElement data={value.data} columnIndex={index} />
            </Container>
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
}

export default React.memo(ListXMainElements);
