import {useTheme} from '@material-ui/core';
import {useTranslation} from 'lib/i18n';
import React, {useCallback, useMemo} from 'react';
import {isLoaded} from 'adapter/loading.adapter';
import {isFunction} from 'lodash';
import {formatCurrency, formatPercent} from 'lib/currency';
import Button from 'components/common/button/Button';
import {PRODUCT_FUND_ACCESS} from 'constants/illustrator';
import clsx from 'clsx';
import {useProductContext} from 'contexts/product-context';
import {parentheses} from 'lib/utils';

export function useProductDetails(props = {}) {
  const {product = {}, loading, ...rest} = props;

  const {getData, getTableData} = useProductDetailsCallback({...rest, withCashOutMethod: false});

  const {productDetails: {additionalComparisonViewData = []} = {}} = useProductContext();

  const productLoaded = useMemo(() => isLoaded({loading}), [loading]);
  const tableData = useMemo(
    () => getTableData(product, additionalComparisonViewData),
    [product, getTableData, additionalComparisonViewData],
  );
  const data = useMemo(() => getData(product), [product, getData]);

  return {
    productLoaded,
    tableData,
    data,
  };
}

export function useProductDetailsCallback(props = {}) {
  const {
    illustrateProduct,
    cashOutMethodClassName,
    withCashOutMethod = true,
    withIllustrate = false,
  } = props;
  const theme = useTheme();
  const {t, cashAtCloseLabel} = useTranslation();

  const onCashOutMethodClick = useCallback(
    (product) => (event) => {
      const {fundAccess} = event.currentTarget.dataset;

      if (isFunction(illustrateProduct)) illustrateProduct(product, fundAccess);
    },
    [illustrateProduct],
  );

  const getData = useCallback(
    ({calculation: {FirstLien, OtherLiens, SimplifiedCashToBorrower, Liens, RemainingEquity} = {}} = {}) => [
      {
        primary: t('illustratorTranslation:product results.product result pie.first lien'),
        secondary: FirstLien,
        key: 'FirstLien',
        color: "red",
        exclude: true,
      },
      {
        primary: cashAtCloseLabel('illustratorTranslation:product results.product result pie.available cash', {
          cashAtClose: SimplifiedCashToBorrower,
        }),
        secondary: SimplifiedCashToBorrower,
        key: 'SimplifiedCashToBorrower',
        color: theme.palette.primary.main,
      },
      {
        primary: t('illustratorTranslation:product results.product result pie.mortgage liens payoff'),
        secondary: FirstLien + OtherLiens,
        key: 'Liens',
        color: theme.palette.neutral.light,
      },
      {
        primary: t('illustratorTranslation:product results.product result pie.remaining equity'),
        secondary: RemainingEquity,
        key: 'RemainingEquity',
        color: theme.palette.secondary.main,
      },
    ],
    [t, cashAtCloseLabel, theme],
  );

  const getTableData = useCallback(
    (product = {}, additionalTableData = []) => {
      const {
        ApiRoute,
        calculation: {
          hideModelImpactButton,
          TotalInitialRate,
          PrincipalLimit,
          ClosingCost,
        } = {},
      } = product;

      const dataMapping = {
        'PrincipalLimit': {order: 1, text: t('illustratorTranslation:product results.product result pie.PrincipalLimit')},
        'SimplifiedCashToBorrower': {order: 2, text: t('illustratorTranslation:product results.product result pie.available cash')},
        'Liens': {order: 3, text: t('illustratorTranslation:product results.product result pie.mortgage liens payoff')},
        'ClosingCost': {order: 4, text: t('illustratorTranslation:product results.product result pie.closing cost')},
        'TotalInitialRate': {order: 5, text: t('illustratorTranslation:product results.product result pie.interest rate')},
        'RemainingEquity': {order: 6, text: t('illustratorTranslation:product results.product result pie.remaining equity')},
      };

      let filterData = getData(product)
        .filter((entity) => !entity.exclude)
        .map((entity) => ({
          ...entity,
          secondary: (entity.secondary < 0) 
            ? parentheses(formatCurrency(entity.secondary * -1))
            : formatCurrency(entity.secondary),
          negative: entity.secondary < 0,
        }));

      filterData.push(
        {
          secondary: formatPercent(TotalInitialRate),
          key: 'TotalInitialRate',
        },
        {
          secondary: formatCurrency(PrincipalLimit),
          key: 'PrincipalLimit',
        },
        {
          secondary: formatCurrency(ClosingCost),
          key: 'ClosingCost',
        },
        ...additionalTableData,
      );

      if (withCashOutMethod) {
        const cashOutMethods = PRODUCT_FUND_ACCESS[ApiRoute] || [];

        filterData.push({
          primary: t('illustratorTranslation:product results.product result pie.cashOutMethod'),
          secondary:
            cashOutMethods.map((FundAccess) => (
              <Button
                key={FundAccess.value}
                className={clsx(cashOutMethodClassName)}
                component="p"
                variant="text"
                data-fund-access={FundAccess.value}
                onClick={onCashOutMethodClick(product)}
              >
                {t(FundAccess.label)}
              </Button>
            )) ?? [],
          key: 'cashOutMethod',
        });
      }

      /* the "Illustrate" button */
      if (withIllustrate) {
        filterData.push({
          primary: '',
          secondary: !hideModelImpactButton ? (
            <Button
              btnStyle="bgElectric"
              onClick={onCashOutMethodClick(product)}
              buttonStyling={{lg: true}}
              data-testid={`illustrate-${product.ProductValue}-button`}
            >
              {t('illustratorTranslation:product comparison.illustrate')}
            </Button>
          ) : null,
          key: 'illustrate',
        });
      }

      filterData = filterData.map((item => ({
        ...item,
        primary: dataMapping[item.key]?.text || item.primary,
        order: dataMapping[item.key]?.order || Number.MAX_SAFE_INTEGER,
        color: (item.negative)? "red" : theme.palette.primary.dark,
      })));

      return filterData.sort((a, b) => a.order - b.order);
    },
    [
      withCashOutMethod,
      getData,
      t,
      theme.palette.primary.dark,
      withIllustrate,
      cashOutMethodClassName,
      onCashOutMethodClick,
    ],
  );

  return {
    getData,
    getTableData,
  };
}
