import React, {useMemo} from 'react';
import Switch from 'components/common/form/switch/Switch';
import {API_ROUTES, FUND_ACCESS, PRODUCT_FUND_ACCESS} from 'constants/illustrator';
import Input from 'components/common/form/input/Input';
import yup from 'lib/validation';
import {getAdvancedOptionsFieldDefaultValidation, getFundAccessValidation} from 'lib/validation/validation';
import {useTranslation} from 'lib/i18n';
import tierImg from 'assets/images/products/tier.svg';
import TierRatesSelect from 'components/products/tier/TierRatesSelect';
import FundAccessInput from 'components/common/inputs/FundAccessInput';
import {map} from 'lodash';
import {formatCurrency} from 'lib/currency';

const additionalStepValidation = yup.object({
  HomeSafeMetricId: yup.number(),
  LenderCredit: yup.bool(),
  CashAtClose: yup.number().nullable(),
  ClosingCost: yup.number().nullable(),
  OverrideClosingCosts: yup.bool(),
  FundAccess: getFundAccessValidation({values: map(PRODUCT_FUND_ACCESS[API_ROUTES.TIER], 'value')}),
  advancedOptions: getAdvancedOptionsFieldDefaultValidation({
    ApiRoute: API_ROUTES.TIER,
    annualPayment: yup.number().max(0),
    monthlyPayment: yup.number().max(0),
  }),
});

export function useTierHook(props = {}) {
  const {t} = useTranslation();

  return {
    description: t('illustratorTranslation:illustration.products.tier.description'),

    img: tierImg,

    lifetimeModelOptionHeader: useMemo(
      () => [
        {
          key: 'homeValueYearEnd',
          group: 1,
          formatter: (props) => formatCurrency(props.row.homeValueYearEnd),
          minWidth: 100,
          listData: 'homeValueYearStart',
        },
        {
          key: 'loanBalanceValueYearEnd',
          group: 2,
          formatter: (props) => formatCurrency(props.row.loanBalanceValueYearEnd),
          minWidth: 100,
          listData: 'loanBalanceValueYearStart',
        },
      ],
      [],
    ),
    additionalAdvancedOptionHeader: [],
    additionalStepTitle: t('illustratorTranslation:illustration.products.tier.additional step title'),
    skipAdditionalStep: true,
    additionalStepValidation,
    additionalQuickFill: useMemo(() => [<Switch order="6" name="OverrideClosingCosts" defaultValue={false} />], []),
    additionalStep: useMemo(
      () => [
        <TierRatesSelect order="1" name="HomeSafeMetricId" />,
        <Switch order="2" name="LenderCredit" defaultValue={false} />,
        <Input order="3" name="CashAtClose" type="currency" defaultValue={0} />,
        <Input order="5" name="ClosingCost" type="currency" defaultValue={0} />,
        <FundAccessInput order="7" type="hidden" defaultValue={FUND_ACCESS.LUMP_SUM} ApiRoute={API_ROUTES.TIER} />,
      ],
      [],
    ),
  };
}
