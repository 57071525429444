import {ToNumber, ToNumberNullable, ToNumberOrDefault, ToString} from 'lib/utils';
import {useCallback} from 'react';
import {HOME_SAFE} from 'constants/products';
import {useHomeSafeValidation} from 'query/products/home-safe/homesafe-validation';
import {defaultsDeep} from 'lodash';
import {useHomeSafeMetricsQuery} from 'query/products/home-safe/homesafe-metrics';
import {formatPercentage} from 'lib/currency';

export function useCalculateSecondQuery(props) {
  const {data: tiers = [], defaultTier} = useHomeSafeMetricsQuery({
    ProductId: props?.product?.ProductId,
  });

  const addAdditionalData = useCallback(
    (data, illustration) => {
      const HomeSafeMetricId = ToString(
        tiers.find((tier) => tier.HomeSafeMetricId === illustration.HomeSafeMetricId)?.HomeSafeMetricId,
        defaultTier?.HomeSafeMetricId,
      );
      const Rate = ToNumberOrDefault(tiers.find((tier) => tier.Rate === illustration.Margin)?.Rate, defaultTier?.Rate);

      return {
        Type: HOME_SAFE,
        HomeSafeType: 'Second',
        HomeSafeMetricId,
        Rate,
      };
    },
    [defaultTier, tiers],
  );

  const addAdditionalResults = useCallback(
    (data) => {
      const LenderCredit = !!tiers.find((tier) => tier.LenderCredit === data.Rate);

      return {
        LenderCredit,
        TotalInitialRate: ToNumber(tiers.find((tier) => tier.HomeSafeMetricId === data.HomeSafeMetricId)?.Rate),
        HomeSafeMetricId: ToNumber(data.HomeSafeMetricId, ToNumber(defaultTier?.HomeSafeMetricId)),
        showLearnMore: false,
        hideModelImpactButton: false,
        Rate: ToNumberNullable(data.Rate),
        HomeSafeMetric: defaultsDeep(
          {
            HomeSafeMetricId: data?.HomeSafeMetric?.HomeSafeMetricId || '',
            Name: data?.HomeSafeMetric?.Name || '',
            Rate: data?.HomeSafeMetric?.Rate || '',
            OriginationFee: data?.HomeSafeMetric?.OriginationFee || '',
          },
          {...data?.HomeSafeMetric},
        ),
      };
    },
    [tiers, defaultTier],
  );

  const {addProductValidation} = useHomeSafeValidation();

  return {
    enabled: !!defaultTier,
    addAdditionalData,
    addAdditionalResults,
    addProductValidation,
    tiers,
    defaultTier,
    getTierLabel: useCallback((tier = {}) => {
      return formatPercentage(tier.Rate);
    }, []),
  };
}
