import {makeStyles} from '@material-ui/core/styles';
import {isSmSizeAppQuery} from 'lib/app-theme';

const useStyles = makeStyles(
  (theme) => ({
    content: {
      backgroundColor: theme.palette.white.light,
      display: theme.withPrint('flex', 'block'),
    },
    text2left: {
      textAlign: 'left',
    },
    listXMainElementContainer: {
      padding: theme.spacing(2, 3),
      [isSmSizeAppQuery(theme)]: {padding: theme.spacing(1)},
    },
    listItemText: {
      '& span': {},
    },
  }),
  {index: 1},
);

export default useStyles;
