import React, {useMemo} from 'react';

/* material ui components */
import Paper from '@material-ui/core/Paper';

/* components */
import Title from 'components/common/typeography/title/Title';

/* custom styles */
import useStyles from './PaymentBreakdownStyles';

/* subcomponents */
import clsx from 'clsx';
import {useTheme} from '@material-ui/core';

import SmallPieChartWithHorizontalLegend from './components/small-pie-chart-with-horizontal-legend/SmallPieChartWithHorizontalLegend';
import {useTranslation} from 'lib/i18n';
import LinearLoader from 'components/common/loader/LinearLoader';
import {ToNumber} from 'lib/utils';
import withWidth from 'components/common/with-width';
import ListXMainElements from './components/list-x-mainElements-y-subElements/ListXMainElements';
import SmallPieChart from './components/small-pie-chart/SmallPieChart';
import {useProductContext} from 'contexts/product-context';
import {useIllustrationContext} from 'contexts/illustration-context';
import {isPending} from 'adapter/loading.adapter';
import Item from 'components/common/layout/item/Item';
import Container from 'components/common/layout/container/Container';
import {formatCurrency} from 'lib/currency';
import {usePrintContext} from 'contexts/print-context';
import {formatPaymentBreakdownValue} from 'lib/product';

function PaymentBreakdown(props) {
  const {
    xs = 7,
    md = 7,
    className,
    showTitle = true,
    classNameListElements,
    isAnimationActive,
    noMargin,
    childrenContainerClassName,
  } = props;

  const {id, SimplifiedCashToBorrower = 0, RemainingEquity = 0, ClosingCost = 0, FirstLien = 0, OtherLiens = 0} = useIllustrationContext();
  const {loadingProduct} = useProductContext();

  const isLoading = useMemo(() => isPending({loading: loadingProduct}), [loadingProduct]);
  const theme = useTheme();

  const classes = useStyles();
  const {t, cashAtCloseLabel} = useTranslation();

  const cashToBorrowerColor = useMemo(() => {
    return SimplifiedCashToBorrower < 0 ? theme.palette.secondary.main : theme.palette.secondary.dark;
  }, [theme.palette.secondary.main, theme.palette.secondary.dark, SimplifiedCashToBorrower]);

  const cashToBorrowerTitleColor = useMemo(() => {
    return SimplifiedCashToBorrower < 0 ? "red" : null;
  }, [SimplifiedCashToBorrower]);

  const data4pieChart = useMemo(() => {
    const CashToBorrower = ToNumber(SimplifiedCashToBorrower);

    if (isLoading) return [];

    return [
      {
        label: t('illustratorTranslation:payment breakdown.first lien'),
        value: FirstLien,
        formatValue: formatCurrency(FirstLien),
        color: "red",
        hide: false,
      },
      {
        label: t('illustratorTranslation:payment breakdown.other liens'),
        value: OtherLiens,
        formatValue: formatCurrency(OtherLiens),
        color: "orange",
        hide: OtherLiens == null || OtherLiens === 0,
      },
      {
        label: cashAtCloseLabel('illustratorTranslation:payment breakdown.cash to borrower', {
          cashAtClose: CashToBorrower,
        }),
        value: CashToBorrower,
        formatValue: formatPaymentBreakdownValue(CashToBorrower),
        color: cashToBorrowerColor,
        titleColor: cashToBorrowerTitleColor,
        tooltip() {
          return CashToBorrower;
        },
        hide: false,
      },
      {
        label: t('illustratorTranslation:payment breakdown.closing cost'),
        value: ClosingCost,
        formatValue: formatCurrency(ClosingCost),
        color: theme.palette.neutral.light,
        hide: false,
      },
      {
        label: t('illustratorTranslation:payment breakdown.remaining equity'),
        value: RemainingEquity,
        formatValue: formatCurrency(RemainingEquity),
        color: theme.palette.primary.main,
        hide: false,
      },
    ].filter((element) => !element.hide);
  }, [
    SimplifiedCashToBorrower,
    ClosingCost,
    RemainingEquity,
    theme,
    cashToBorrowerColor,
    cashToBorrowerTitleColor,
    t,
    cashAtCloseLabel,
    isLoading,
    FirstLien,
    OtherLiens,
  ]);

  const {printing} = usePrintContext();
  const pieChartBreakpoints = useMemo(() => (printing ? 5 : 12), [printing]);
  const legendBreakpoints = useMemo(() => (printing ? 7 : 12), [printing]);

  return (
    <Item xs={xs} md={md} className={className} noMargin={noMargin}>
      <LinearLoader loading={loadingProduct} childrenContainerClassName={childrenContainerClassName}>
        <Paper variant="outlined" square>
          <Container className={classes.p2}>
            {showTitle && (
              <Item className={classes.p2} xs={12}>
                <Title variant="h4">{t('illustratorTranslation:illustration breakdown.payment-breakdown.title')}</Title>
              </Item>
            )}

            {/* graph section / start */}
            <Item className={classes.p2} xs={12}>
              <Container>
                <Item xs={pieChartBreakpoints} sm={5} lg={3} className={clsx(classes.flexElement)}>
                  <SmallPieChart
                    className={classes.smPieChartElement}
                    key={id}
                    data={data4pieChart}
                    isAnimationActive={isAnimationActive}
                  />
                </Item>
                <Item
                  xs={legendBreakpoints}
                  sm={7}
                  lg={9}
                  className={clsx(classes.flexElement, classes.smPieChartItem)}
                >
                  <Container className={clsx(classes.smPieChart)}>
                    {data4pieChart.map((element) => (
                      <SmallPieChartWithHorizontalLegend
                        key={element.label}
                        className={clsx(classes.smPieChartLegend)}
                        title={element.label}
                        titleColor={element.titleColor}
                        color={element.color}
                        value={element.formatValue}
                        dataTestId={`product-printout-page-payment-breakdown-${element.label}`}
                      />
                    ))}

                     {/* Adding a dummy element for resolving last row alignment issue in flex. */}
                     <SmallPieChartWithHorizontalLegend
                        key='dummy'
                        className={clsx(classes.smPieChartLegendDummy)}
                      />
                  </Container>
                </Item>
              </Container>
            </Item>
            {/* graph section / stop */}

            {/* list section / start */}
            <Item xs={12} className={classNameListElements}>
              <ListXMainElements />
            </Item>
            {/* list section / stop */}
          </Container>
        </Paper>
      </LinearLoader>
    </Item>
  );
}

export default withWidth(PaymentBreakdown);
