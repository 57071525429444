import React, {useMemo} from 'react';
import {formatCurrency, formatPercent} from 'lib/currency';
import {useTranslation} from 'lib/i18n';
import {getBorrowerBirthDate} from 'lib/borrower';
import {usePrintContext} from 'contexts/print-context';
import {useBooleanToString} from 'hooks/currency';
import {parentheses} from 'lib/utils';
import {isPaymentBreakdownValueNegative, formatPaymentBreakdownValue} from 'lib/product';

export function useSecondOverallResults(props = {}) {
  const {
    product: {
      ProductName,
      calculation: {
        BorrowerProfile: {
          Age,
          DateOfBirth,
          Liens,
          ValueOfProperty,
          ExistingMortgage: {TaxesAndInsuranceReserve} = {},
          FirstLien,
          OtherLiens,
        } = {},
        PrincipalLimit,
        ClosingCost,
        CashAtClose,
        Rate,
        ServiceFee,
        HomeSafeMetricId,
        FundAccess,
        Lesa,
        OverrideClosingCosts,
        RemainingPrincipalLimit,
        LenderCredit,
        HomeSafeMetric: {OriginationFee} = {},
      } = {},
    } = {},
  } = props;
  const {t, cashAtCloseLabel} = useTranslation();
  const {printing} = usePrintContext();

  const {booleanToString} = useBooleanToString();

  /**
   *
   * The "overallResults" data is used on the Product PDF Printout page, within the "Payment Breakdown" section.
   *
   * The "overallResults" data represents the "Principal Limit Factor" section data.
   *
   */

  const overallResults = useMemo(() => {
    return [
      {
        label: t('illustratorTranslation:illustration.overall results.home value'),
        value: formatCurrency(ValueOfProperty),
        order: 1,
        id: 'home-value',
      },
      {
        label: t('illustratorTranslation:illustration.overall results.principal limit'),
        tooltip: t('illustratorTranslation:illustration.overall results.principal limit tooltip'),
        value: formatCurrency(PrincipalLimit),
        order: 2,
        id: 'principal-limit',
        options: [
          {
            label: t('illustratorTranslation:illustration.overall results.mortgage balance'),
            value: parentheses(formatCurrency(Liens)),
            useColorOptions: {value: 'red'},
            order: 3,
            id: 'mortgage-balance',
            options: [
              {
                label: t('illustratorTranslation:illustration.overall results.first mortgage'),
                value: parentheses(formatCurrency(FirstLien)),
                useColorOptions: {value: 'red'},
                id: 'first-mortgage',
              },
              {
                label: t('illustratorTranslation:illustration.overall results.other mortgages'),
                value: parentheses(formatCurrency(OtherLiens)),
                useColorOptions: {value: 'red'},
                id: 'other-mortgages',
              },
            ],
          },
          {
            label: t('illustratorTranslation:illustration.overall results.closing cost'),
            value: parentheses(formatCurrency(ClosingCost)),
            useColorOptions: {value: 'red'},
            order: 4,
            id: 'closing-cost',
          },
        ],
      },

      {
        label: cashAtCloseLabel('illustratorTranslation:illustration.overall results.cash at close', {
          cashAtClose: CashAtClose,
        }),
        value: formatPaymentBreakdownValue(CashAtClose),
        useColorOptions: {value: isPaymentBreakdownValueNegative(CashAtClose) ? 'red' : undefined},
        order: 6,
        id: 'cash-at-close',
      },
    ];
  }, [t, cashAtCloseLabel, ValueOfProperty, PrincipalLimit, Liens, FirstLien, OtherLiens, ClosingCost, CashAtClose]);

  /**
   *
   * The "overallResultsSecond" data is used on the Product PDF Printout page, within the "Payment Breakdown" section.
   *
   * The "overallResultsSecond" data represents the "Details" section data.
   *
   */

  const overallResultsSecond = useMemo(() => {
    const data = [
      {
        label: t('illustratorTranslation:illustration.overall results.date of birth'),
        value: getBorrowerBirthDate(DateOfBirth),
        order: 1,
        id: 'date-of-birth',
      },
      {
        label: t('translation:justText.estimated age'),
        tooltip: t('translation:justText.estimated age tooltip'),
        value: Age,
        order: 2,
        id: 'estimated-age',
      },
      {
        label: t('illustratorTranslation:illustration.overall results.Rate'),
        value: formatPercent(Rate),
        order: 3,
        id: 'rate',
      },
      {
        label: t('illustratorTranslation:illustration.overall results.service fee'),
        value: formatCurrency(ServiceFee),
        order: 5,
        id: 'service-fee',
      },
    ];

    if (printing) {
      data.push(
        {
          label: t('illustratorTranslation:illustration.overall results.fund access'),
          value: FundAccess,
          order: 6,
          id: 'fund-access',
        },
        {
          label: t('illustratorTranslation:illustration.overall results.override closing costs'),
          value: booleanToString(OverrideClosingCosts),
          order: 7,
          id: 'override-closing-costs',
        },
      );
    }
    return data;
  }, [
    t,
    DateOfBirth,
    Age,
    Rate,
    OriginationFee,
    ServiceFee,
    printing,
    FundAccess,
    booleanToString,
    OverrideClosingCosts,
  ]);

  return {
    overallResults,
    overallResultsSecond,
  };
}
