import React, {useCallback} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import SwitchElement from './components/SwitchElement';
import {useInputError, useInputLabel, useValidationSchema} from 'hooks/input';
import PropTypes from 'prop-types';
import {isFunction} from 'lodash';

function Switch(props) {
  const {onOffFlag, label, name, onChange: onChangeExternal, ...rest} = props;
  const {control} = useFormContext();

  const inputLabel = useInputLabel({label, name});

  const onChangeHandler = useCallback(
    (event) => {
      if (isFunction(onChangeExternal)) {
        onChangeExternal(event, name);
      }
    },
    [name, onChangeExternal],
  );

  const {required} = useValidationSchema({name});
  const {showError, errorMessage} = useInputError({name});

  return (
    <Controller
      variant="outlined"
      name={name}
      {...rest}
      label={inputLabel}
      render={({field: {onChange, value, ref}}) => {
        return (
          <SwitchElement
            ref={ref}
            label={inputLabel}
            value={value}
            name={name}
            required={required}
            showError={showError}
            errorMessage={errorMessage}
            onOffFlag={onOffFlag}
            {...rest}
            onChange={(e) => {
              onChange(e);
              onChangeHandler(e);
            }}
          />
        );
      }}
      control={control}
    />
  );
}

Switch.defaultProps = {
  size: 'small',
  variant: 'h6',
};

Switch.propTypes = {
  variant: PropTypes.oneOf(['h6']),
};

export default Switch;
